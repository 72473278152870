<template>
	<div>
		<nav class="flex flex-col md:flex-row md:items-center bg-header dark:bg-black md:h-16 px-3 py-2 md:px-8">
			<router-link @click.native="open = false" :to="usuario.monitoramento || usuario.financeiro ? '/' : 'admin/numeros'">
				<img src="../../public/lembraZapLogoBranco.png" alt="Logo LembraZap" width="100" />
			</router-link>

			<svg
				@click="open = !open"
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
				stroke-width="1.5"
				stroke="currentColor"
				:class="{block: !open, hidden: open}"
				class="sm:hidden text-white top-4 right-5 w-6 h-6 absolute"
			>
				<path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
			</svg>

			<svg
				@click="open = !open"
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
				stroke-width="1.5"
				stroke="currentColor"
				:class="{block: open, hidden: !open}"
				class="sm:hidden text-white top-4 right-5 w-6 h-6 absolute"
			>
				<path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
			</svg>

			<div class="md:ml-5 select-none text-white font-medium w-full md:flex space-x-1 2xl:space-x-2" :class="open ? 'flex' : 'hidden'">
				<ul class="block md:flex text-sm">
					<li
						class="opacity-75 my-2 md:mx-2 hover:opacity-100 hover:border-b"
						v-if="usuario.monitoramento || usuario.financeiro"
						:class="{'border-b': route.path === '/admin', 'opacity-100': route.path === '/admin'}"
					>
						<router-link @click.native="open = false" to="/admin">Home</router-link>
					</li>
					<li
						class="opacity-75 my-2 md:mx-2 hover:opacity-100 hover:border-b"
						v-if="usuario.monitoramento && !usuario.financeiro"
						:class="{'border-b': route.path === '/admin/usuarios', 'opacity-100': route.path === '/admin/usuarios'}"
					>
						<router-link @click.native="open = false" to="/admin/usuarios">Usuários</router-link>
					</li>
					<li
						v-if="!usuario.financeiro"
						class="opacity-75 my-2 md:mx-2 hover:opacity-100 hover:border-b"
						:class="{'border-b': route.path === '/admin/ranking', 'opacity-100': route.path === '/admin/ranking'}"
					>
						<router-link @click.native="open = false" to="/admin/ranking">Ranking</router-link>
					</li>
					<li
						class="opacity-75 my-2 md:mx-2 hover:opacity-100 hover:border-b"
						:class="{'border-b': route.path === '/admin/desempenho', 'opacity-100': route.path === '/admin/desempenho'}"
					>
						<router-link @click.native="open = false" to="/admin/desempenho">Desempenho</router-link>
					</li>
					<li
						class="opacity-75 my-2 md:mx-2 hover:opacity-100 hover:border-b"
						v-if="usuario.monitoramento"
						:class="{'border-b': route.path === '/admin/emuladores', 'opacity-100': route.path === '/admin/emuladores'}"
					>
						<router-link @click.native="open = false" to="/admin/emuladores">Emuladores</router-link>
					</li>
					<li
						class="opacity-75 my-2 md:mx-2 hover:opacity-100 hover:border-b"
						v-if="usuario.monitoramento"
						:class="{'border-b': route.path === '/admin/auditoria', 'opacity-100': route.path === '/admin/auditoria'}"
					>
						<router-link @click.native="open = false" to="/admin/auditoria">Auditoria</router-link>
					</li>
					<li
						class="opacity-75 my-2 md:mx-2 hover:opacity-100 hover:border-b"
						:class="{'border-b': route.path === '/admin/codigos-estrangeiros', 'opacity-100': route.path === '/admin/codigos-estrangeiros'}"
					>
						<router-link @click.native="open = false" to="/admin/codigos-estrangeiros">Códigos estrangeiros</router-link>
					</li>
					<li
						v-if="!usuario.financeiro"
						class="opacity-75 my-2 md:mx-2 hover:opacity-100 hover:border-b"
						:class="{'border-b': route.path === '/admin/numeros', 'opacity-100': route.path === '/admin/numeros'}"
					>
						<router-link @click.native="open = false" to="/admin/numeros">Números</router-link>
					</li>
					<li
						v-if="!usuario.financeiro"
						class="opacity-75 my-2 md:mx-2 hover:opacity-100 hover:border-b"
						:class="{'border-b': route.path === '/admin/banidos', 'opacity-100': route.path === '/admin/banidos'}"
					>
						<router-link @click.native="open = false" to="/admin/banidos">Banidos</router-link>
					</li>
					<li
						v-if="!usuario.financeiro"
						class="opacity-75 my-2 md:mx-2 hover:opacity-100 hover:border-b"
						:class="{'border-b': route.path === '/admin/suporte', 'opacity-100': route.path === '/admin/suporte'}"
					>
						<router-link @click.native="open = false" to="/admin/suporte">Suporte</router-link>
					</li>
				</ul>
			</div>
			<span
				:class="open ? 'block' : 'hidden'"
				class="cursor-pointer md:flex space-x-1 2xl:space-x-2 text-white text-lg font-base mt-3 md:mt-0 md:mx-4"
				v-if="usuario"
				@click="modal()"
			>
				{{ usuario.user }}
			</span>
			<div @click="deslogar" :class="open ? 'block' : 'hidden'" class="md:flex space-x-1 2xl:space-x-2">
				<button
					class="mt-1 p-2 md:px-4 md:py-2 rounded-md bg-red-600 border-b-2 border-r-2 border-red-800 text-sm text-white font-medium transition duration-300 ease-in-out hover:shadow-md hover:bg-red-800 hover:text-opacity-50"
				>
					Sair
				</button>
			</div>
		</nav>
		<vue-final-modal v-model="closed" name="modal" class="overflow-auto">
			<div id="config" class="bg-gray-100 dark:bg-dark rounded-md h-50 w-30vh sm:w-60vh mx-auto translate-y-36 sm:translate-y-24 p-6">
				<svg
					@click="modal(true)"
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
					stroke-width="1.5"
					stroke="currentColor"
					class="text-red-500 w-6 h-6 cursor-pointer absolute top-2 right-5"
				>
					<path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
				</svg>
				<h1 class="text-xl text-header dark:text-white font-bold mb-1">{{ usuario.user }}</h1>
				<div class="bg-white dark:bg-dark my-5 px-2 py-2 shadow rounded-md overflow-auto" :class="formRadio === '' ? 'h-24' : 'h-96'">
					<h2 class="text-xl text-zinc-500 dark:text-white font-semibold mb-1 text-center">O que deseja fazer?</h2>
					<div class="flex justify-around">
						<label class="font-medium text-zinc-500 dark:text-white block my-1">
							<input class="ml-1" type="radio" value="senha" v-model="formRadio" />
							Alterar senha
						</label>
						<label class="font-medium text-zinc-500 dark:text-white block my-1">
							<input class="ml-1" type="radio" value="obs" v-model="formRadio" />
							Observação (atividade do dia)
						</label>
					</div>
					<form @submit.prevent="mandarObservacao" v-if="formRadio === 'obs'">
						<div class="text-center">
							<label class="block my-3 text-zinc-500 dark:text-white font-medium">Escreva sua observação</label>
							<textarea
								rows="5"
								:required="true"
								class="dark:bg-zinc-800 dark:focus:border-zinc-900 dark:focus:ring-1 dark:focus:ring-zinc-900 dark:hover:border-zinc-500 dark:hover:ring-1 dark:hover:ring-zinc-600 dark:border-zinc-700 dark:text-zinc-400 dark:placeholder:text-zinc-500 w-full font-lato font-medium text-base border rounded-md box-border y-3 sm:py-5 px-2 focus:outline-none focus:border-blue-900 focus:ring-1 focus:ring-blue-900 hover:border-blue-300 hover:ring-1 hover:ring-blue-900"
								v-model="relatorio"
								tipo="text"
								placeholder="Digite sua observação aqui..."
							>
							</textarea>
							<button
								type="submit"
								class="mt-3 w-1/3 px-3 py-2 text-sm rounded-md text-center text-white font-medium bg-blue-600 dark:bg-zinc-800 p-2 border-b-2 border-r-2 border-blue-800 dark:border-zinc-900 transition duration-300 ease-in-out hover:shadow-md hover:bg-blue-800 dark:hover:bg-zinc-900 hover:text-opacity-50"
							>
								Enviar
							</button>
						</div>
					</form>
					<form @submit.prevent="alterarSenha" v-if="formRadio === 'senha'">
						<label class="block my-3 text-zinc-500 dark:text-white font-medium">Sua senha atual:</label>
						<CampoDeTexto v-model="senha.atual" class="w-1/2 h-10 mb-2" tipo="password" holder="Senha atual" />

						<label class="block my-3 text-zinc-500 dark:text-white font-medium">Sua nova senha:</label>
						<CampoDeTexto v-model="senha.nova" class="w-1/2 h-10 mb-2" tipo="password" holder="Senha atual" />

						<label class="block my-3 text-zinc-500 dark:text-white font-medium">Confirme a nova senha:</label>
						<CampoDeTexto v-model="senha.confirmacao" class="w-1/2 h-10 mb-2" tipo="password" holder="Senha atual" />

						<button
							type="submit"
							class="block mt-3 w-1/3 px-3 py-2 text-sm rounded-md text-center text-white font-medium bg-blue-600 dark:bg-zinc-800 p-2 border-b-2 border-r-2 border-blue-800 dark:border-zinc-900 transition duration-300 ease-in-out hover:shadow-md hover:bg-blue-800 dark:hover:bg-zinc-900 hover:text-opacity-50"
						>
							Enviar
						</button>
					</form>
				</div>
			</div>
		</vue-final-modal>
		<RouterView></RouterView>
	</div>
</template>
<script setup>
	import {ref, computed, inject} from "vue";
	import {useStore} from "vuex";
	import {realiza_logout} from "@/store/typeActions.js";
	import {useRouter, useRoute} from "vue-router";
	import {getUsuario} from "@/services/auth.js";
	import CampoDeTexto from "@/components/campoDeTexto.vue";
	import {toast} from "@/hooks/toast";
	import api from "@/services/api.js";

	const store = useStore();
	const router = useRouter();
	const route = useRoute();
	const $vfm = inject("$vfm");
	const open = ref(false);
	const closed = ref(false);
	const formRadio = ref("");
	const relatorio = ref("");
	const senha = ref({atual: "", nova: "", confirmacao: ""});
	const usuario = computed(() => store.state.usuario);

	const deslogar = () => {
		store.dispatch(realiza_logout);
		router.push({name: "Login"});
		toast("LogOut efetuado com sucesso!", "success");
	};

	const modal = fecharModal => (fecharModal ? $vfm.hide("modal") : $vfm.show("modal"));

	const mandarObservacao = async () => {
		const usuario = getUsuario();
		const response = await api.post("v1/users/atividade", {
			usuario,
			observacao: relatorio.value,
		});

		if (!response.data.sucess) return toast(response.data.err, "error");

		modal(true);
		toast("Observação enviada!", "success");
	};

	const alterarSenha = async () => {
		if (senha.value.nova !== senha.value.confirmacao || senha.value.nova.trim() === "") return toast("Senha e confirmação não conferem!", "error");

		const response = await api.post("/v1/users/atualizaSenha", {
			password: senha.value.atual,
			newpassword: senha.value.nova,
		});

		if (!response.data.success) {
			return toast(response.data.err, "error");
		}

		modal(true);
		toast("Senha alterada com sucesso", "success");
	};
</script>
