<template>
	<div class="my-2 mx-4 md:my-5 md:mx-8">
		<TituloPagina titulo="Números" />
		<div class="grid grid-cols-12 gap-4 mb-5">
			<Fluxograma :contas="contas.etapas" class="col-span-12" />
			<Tabela
				titulo="Fluxo Principal"
				:contas="contas.fluxoPrincipal"
				cor="text-zinc-600 dark:text-zinc-200"
				class="col-span-12 md:col-span-6 xl:col-span-4"
				span="md:col-span-6 2xl:col-span-4"
			/>
			<Tabela
				titulo="Controle Envios"
				:contas="contas.contasEnvios"
				cor="text-zinc-600 dark:text-zinc-200"
				class="col-span-12 md:col-span-6 xl:col-span-4"
				span="md:col-span-6 xl:col-span-4 2xl:col-span-3"
				v-if="usuarioAdmin.monitoramento"
			/>
			<Tabela
				titulo="Controle Massivo"
				:contas="contas.controleEnvios"
				cor="text-zinc-600 dark:text-zinc-200"
				class="col-span-12 md:col-span-6 xl:col-span-4"
				span="md:col-span-6 2xl:col-span-4"
			/>
			<Tabela titulo="Sockets" :contas="contas.sockets" cor="text-blue-600" class="col-span-12 md:col-span-6 xl:col-span-4" span="md:col-span-3 xl:col-span-4" />
			<Tabela
				titulo="Emuladores"
				:contas="contas.emuladores"
				cor="text-sky-600"
				class="col-span-12 md:col-span-6 xl:col-span-4"
				span="md:col-span-6 xl:col-span-3 2xl:col-span-2"
			/>
			<Tabela
				titulo="Clientes personalizados"
				:contas="contas.clientesPersonalizados"
				cor="text-cyan-600"
				class="col-span-12 md:col-span-6 xl:col-span-4"
				span="md:col-span-6 2xl:col-span-4"
				v-if="usuarioAdmin.monitoramento"
			/>
		</div>
		<form @submit.prevent="updateQuery()" class="grid grid-cols-12 gap-5 mt-3">
			<div class="col-span-12 md:col-span-12">
				<button
					class="md:mr-2 py-3 px-5 h-12 bg-green-600 font-medium text-white text-sm border-b-2 border-r-2 border-green-800 rounded-md transition duration-300 ease-in-out hover:shadow-md hover:bg-green-800 hover:text-opacity-50"
					@click="mostraModalNumero(null)"
				>
					Novo Número
				</button>
				<button
					class="md:mr-2 py-3 px-5 h-12 bg-teal-600 font-medium text-white text-sm border-b-2 border-r-2 border-teal-800 rounded-md transition duration-300 ease-in-out hover:shadow-md hover:bg-teal-800 hover:text-opacity-50"
					@click="mostraModalNumeroCodigo(null)"
				>
					Gerar Código Número
				</button>
				<button
					class="md:mx-2 py-3 px-5 h-12 bg-blue-600 font-medium text-white text-sm border-b-2 border-r-2 border-blue-800 rounded-md transition duration-300 ease-in-out hover:shadow-md hover:bg-blue-800 hover:text-opacity-50"
					@click="getOnlines"
				>
					<span class="flex items-center">
						<span class="mr-4">Refresh onlines</span>
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
							/>
						</svg>
					</span>
				</button>
			</div>
			<div class="col-span-12 md:col-span-3 2xl:col-span-2">
				<textarea
					rows="1"
					class="mt-2 w-full font-lato font-medium text-base border rounded-md box-border y-3 sm:py-5 px-2 focus:outline-none focus:border-blue-900 focus:ring-1 focus:ring-blue-900 hover:border-blue-300 hover:ring-1 hover:ring-blue-900 dark:bg-zinc-800 dark:focus:border-zinc-900 dark:focus:ring-1 dark:focus:ring-zinc-900 dark:hover:border-zinc-500 dark:hover:ring-1 dark:hover:ring-zinc-600 dark:border-zinc-700 dark:text-zinc-400 dark:placeholder:text-zinc-500"
					v-model="query.buscaArray"
					tipo="text"
					placeholder="Buscar número (_id ou telefone)"
				></textarea>
			</div>
			<div class="col-span-12 md:col-span-3 2xl:col-span-2">
				<label class="block mb-1 dark:text-zinc-200 text-base font-medium">PC Emulador</label>
				<CampoDeTexto class="w-full h-8" v-model="query.pcEmulador" tipo="text" holder="Digite o emulador" />
				<label class="font-medium dark:text-zinc-200">
					<input
						class="ml-1 my-2"
						:checked="query.exatoEmulador"
						type="checkbox"
						:disabled="query.naoContemEmulador"
						@input="() => (query.exatoEmulador = !query.exatoEmulador)"
					/>
					Exato
				</label>
				<label class="font-medium dark:text-zinc-200">
					<input
						class="ml-1 my-2"
						:checked="query.naoContemEmulador"
						type="checkbox"
						:disabled="query.exatoEmulador"
						@input="() => (query.naoContemEmulador = !query.naoContemEmulador)"
					/>
					Não contém
				</label>
			</div>
			<div class="col-span-12 md:col-span-3 2xl:col-span-2">
				<label class="block mb-1 dark:text-zinc-200 text-base font-medium">PC Socket</label>
				<CampoDeTexto class="w-full h-8" :disabled="query.semSocket" v-model="query.pcSocket" tipo="text" holder="Digite o socket" />
				<label class="font-medium dark:text-zinc-200">
					<input
						class="ml-1 my-2"
						:checked="query.exatoSocket"
						type="checkbox"
						:disabled="query.naoContemSocket || query.semSocket"
						@input="() => (query.exatoSocket = !query.exatoSocket)"
					/>
					Exato
				</label>
				<label class="font-medium dark:text-zinc-200">
					<input
						class="ml-1 my-2"
						:checked="query.naoContemSocket"
						type="checkbox"
						:disabled="query.exatoSocket || query.semSocket"
						@input="() => (query.naoContemSocket = !query.naoContemSocket)"
					/>
					Não contém
				</label>
			</div>
			<div class="col-span-12 md:col-span-3 2xl:col-span-2">
				<label class="block mb-1 dark:text-zinc-200 text-base font-medium">Usuários</label>
				<v-select
					v-model="query.usuario"
					:reduce="option => option._id"
					class="w-full bg-white dark:bg-zinc-800 dark:text-white dark:select-dark"
					:options="usuarios"
					label="email"
					placeholder="Selecione um usuário"
					multiple
				/>

				<div>
					<label class="font-medium dark:text-zinc-200">
						<input class="ml-1 my-2" type="radio" value="buscar" v-model="query.searchOption" />
						Buscar em
					</label>
					<label class="font-medium dark:text-zinc-200">
						<input class="ml-1 my-2" type="radio" value="naoBuscar" v-model="query.searchOption" />
						Não buscar em
					</label>
				</div>
			</div>
			<div class="col-span-12 md:col-span-2">
				<label class="font-medium dark:text-zinc-200">
					Data criação de
					<input
						type="date"
						@input="event => (query.de = event.target.value)"
						:value="query.de"
						class="dark:bg-zinc-800 dark:border-zinc-700 dark:text-white w-full mt-1 border border-gray-200 rounded-md text-center h-9"
					/>
				</label>
			</div>
			<div class="col-span-12 md:col-span-2">
				<label class="font-medium dark:text-zinc-200">
					até
					<input
						type="date"
						@input="event => (query.ate = event.target.value)"
						:value="query.ate"
						class="dark:bg-zinc-800 dark:border-zinc-700 dark:text-white w-full mt-1 border border-gray-200 rounded-md text-center h-9"
					/>
				</label>
			</div>
			<div class="grid col-span-12 md:col-span-3 2xl:col-span-2">
				<label class="mb-1 text-base font-medium dark:text-zinc-200">Filtros</label>
				<label class="font-medium dark:text-zinc-200">
					<input
						class="ml-1 my-2"
						:checked="query.statusWebOnline"
						type="checkbox"
						@input="() => (query.statusWebOnline = !query.statusWebOnline)"
						:disabled="query.statusWebOffline || query.statusQrCode"
					/>
					Status Web <span class="text-green-500">Online</span>
				</label>
				<label class="font-medium dark:text-zinc-200">
					<input
						class="ml-1 my-2"
						:checked="query.statusWebOffline"
						type="checkbox"
						@input="() => (query.statusWebOffline = !query.statusWebOffline)"
						:disabled="query.statusWebOnline"
					/>
					Status Web <span class="text-red-500">Offline</span>
				</label>
				<label class="font-medium dark:text-zinc-200">
					<input
						class="ml-1 my-2"
						:checked="query.statusQrCode"
						type="checkbox"
						@input="() => (query.statusQrCode = !query.statusQrCode)"
						:disabled="query.statusWebOnline"
					/>
					Status <span class="text-blue-500">QR</span>Code <span class="text-red-500">Offline</span>
				</label>
				<label class="font-medium dark:text-zinc-200">
					<input class="ml-1 my-2" :checked="query.semSocket" type="checkbox" :disabled="query.pcSocket !== ''" @input="() => (query.semSocket = !query.semSocket)" />
					Sem socket
				</label>
				<label class="font-medium dark:text-zinc-200">
					<input class="ml-1 my-2" :checked="query.envioRobo" type="checkbox" @input="() => (query.envioRobo = !query.envioRobo)" />
					Envio Robô
				</label>
			</div>
			<div class="col-span-12 md:col-span-3 2xl:col-span-2">
				<label class="block text-base font-medium mb-2 dark:text-zinc-200">Tipo de Conta</label>
				<label class="font-medium block my-1 dark:text-zinc-200">
					<input class="ml-1" type="radio" value="validador" v-model="query.tipoConta" @click="limpaRadioConta('validador')" />
					Validador
				</label>
				<label class="font-medium block my-1 dark:text-zinc-200">
					<input class="ml-1" type="radio" value="massa" v-model="query.tipoConta" @click="limpaRadioConta('massa')" />
					Massa
				</label>
			</div>
			<div class="col-span-12 md:col-span-3 2xl:col-span-2">
				<label class="block text-base font-medium mb-2 dark:text-zinc-200">
					Números com mais de: <span class="text-base font-medium text-blue-500">{{ query.diasCriacao }}</span>
					{{ query.diasCriacao === "1" ? "dia" : "dias" }}
					<input class="ml-1 h-5 w-2/3" type="range" v-model="query.diasCriacao" min="0" max="100" />
				</label>
			</div>

			<div class="col-span-12">
				<button
					type="submit"
					class="w-72 float-left px-5 py-2 bg-blue-600 font-medium text-white text-sm border-b-2 border-r-2 border-blue-800 rounded-md transition duration-300 ease-in-out hover:shadow-md hover:bg-blue-800 hover:text-opacity-50"
				>
					Buscar
				</button>
			</div>
			<div class="col-span-12">
				<small class="text-gray-800 dark:text-zinc-200"
					><b>Total busca: {{ total }}</b></small
				>
				<label className="ml-2 dark:text-zinc-200">
					<input id="desativados" type="checkbox" :checked="query.desativo" @input="() => (query.desativo = !query.desativo)" />
					Desativados
				</label>
			</div>
			<div class="col-span-12 text-center sm:text-left">
				<button
					@click="download()"
					class="w-4/5 sm:w-auto sm:my-0 mx-2 text-center bg-blue-600 p-2 font-medium text-white text-sm border-b-2 border-r-2 border-blue-800 rounded-md transition duration-300 ease-in-out hover:shadow-md hover:bg-blue-800 hover:text-opacity-50"
				>
					<span class="mr-2">Download</span>
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="inline ml-4 w-4 h-4">
						<path
							stroke-linecap="round"
							stroke-linejoin="round"
							d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
						/>
					</svg>
				</button>
				<button
					class="w-4/5 sm:w-auto my-2 sm:my-0 mx-2 bg-blue-600 p-2 font-medium text-white text-sm border-b-2 border-r-2 border-blue-800 rounded-md transition duration-300 ease-in-out hover:shadow-md hover:bg-blue-800 hover:text-opacity-50"
					@click="copiaTodosOsNumeros()"
				>
					Copiar números
				</button>
				<button
					type="button"
					@click="limparFiltros()"
					class="w-4/5 sm:w-auto mx-2 my-2 sm:my-0 bg-cyan-600 p-2 font-medium text-white text-sm border-b-2 border-r-2 border-cyan-800 rounded-md transition duration-300 ease-in-out hover:shadow-md hover:bg-cyan-800 hover:text-opacity-50"
				>
					Limpar filtros
				</button>
				<button
					v-if="usuarioAdmin.monitoramento"
					type="button"
					@click="mostraModal('modalEnvioCampanha')"
					class="w-4/5 sm:w-auto mx-2 my-2 sm:my-0 bg-orange-600 p-2 font-medium text-white text-sm border-b-2 border-r-2 border-orange-800 rounded-md transition duration-300 ease-in-out hover:shadow-md hover:bg-orange-800 hover:text-opacity-50"
				>
					Envio campanha
				</button>
				<button
					v-if="usuarioAdmin.monitoramento"
					type="button"
					@click="mostraModalEnvioCampanha(lista.length > 0 ? lista.map(item => ({_id: item._id, telefone: item.telefone})) : null)"
					class="w-4/5 sm:w-auto mx-2 my-2 sm:my-0 bg-lime-600 p-2 font-medium text-white text-sm border-b-2 border-r-2 border-lime-800 rounded-md transition duration-300 ease-in-out hover:shadow-md hover:bg-lime-800 hover:text-opacity-50"
				>
					Envio campanha extensão
				</button>
				<button
					v-if="usuarioAdmin.monitoramento"
					type="button"
					@click="mostraModal('mensagemStatusModal')"
					class="w-4/5 sm:w-auto mx-2 my-2 bg-purple-600 p-2 font-medium text-white text-sm border-b-2 border-r-2 border-purple-800 rounded-md transition duration-300 ease-in-out hover:shadow-md hover:bg-purple-800 hover:text-opacity-50"
				>
					Alterar status mensagem
				</button>
				<button
					v-if="usuarioAdmin.monitoramento"
					type="button"
					@click="mostraModal('modalEnvioChats')"
					class="w-4/5 sm:w-auto mx-2 my-2 bg-gray-600 p-2 font-medium text-white text-sm border-b-2 border-r-2 border-gray-800 rounded-md transition duration-300 ease-in-out hover:shadow-md hover:bg-gray-800 hover:text-opacity-50"
				>
					Envio de múltiplos chats
				</button>
				<button
					@click="router.push(`/admin/envios-clientes`)"
					class="w-4/5 sm:w-auto mx-2 my-2 bg-cyan-600 p-2 font-medium text-white text-sm border-b-2 border-r-2 border-cyan-800 rounded-md transition duration-300 ease-in-out hover:shadow-md hover:bg-cyan-800 hover:text-opacity-50"
				>
					Envios clientes LZV2
				</button>
				<button
					@click="openModalPcLimpeza()"
					class="w-4/5 sm:w-auto mx-2 my-2 bg-amber-500 p-2 font-medium text-white text-sm border-b-2 border-r-2 border-amber-800 rounded-md transition duration-300 ease-in-out hover:shadow-md hover:bg-amber-800 hover:text-opacity-50"
				>
					Marcar pcs de limpeza
				</button>
			</div>
		</form>
		<div class="bg-white dark:bg-black my-5 px-2 py-2 shadow rounded-md overflow-auto">
			<table class="w-full divide-y divide-gray-200 dark:divide-zinc-200 text-sm sm:text-base">
				<thead class="select-none cursor-pointer">
					<tr class="text-header sm:text-left">
						<th class="px-2 py-3 dark:text-zinc-200" @click="alteraSort('data')">
							Telefone
							<svg
								v-if="query.sort.sort === 'data'"
								style="{ height: 22, marginLeft: 3 }"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke-width="1.5"
								stroke="currentColor"
								class="w-6 h-6 inline"
							>
								<path stroke-linecap="round" stroke-linejoin="round" d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
							</svg>
						</th>
						<th class="px-2 py-3 dark:text-zinc-200">E-mail</th>
						<th class="px-2 py-3 dark:text-zinc-200" @click="alteraSort('envios')" v-if="usuarioAdmin.monitoramento">
							Envios
							<svg
								v-if="query.sort.sort === 'envios'"
								style="{ height: 22, marginLeft: 3 }"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke-width="1.5"
								stroke="currentColor"
								class="w-6 h-6 inline"
							>
								<path stroke-linecap="round" stroke-linejoin="round" d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
							</svg>
						</th>
						<th class="px-2 py-3 dark:text-zinc-200" @click="alteraSort('chatslzv2_hoje')" v-if="usuarioAdmin.monitoramento">
							Chats
							<svg
								v-if="query.sort.sort === 'chatslzv2_hoje'"
								style="{ height: 22, marginLeft: 3 }"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke-width="1.5"
								stroke="currentColor"
								class="w-6 h-6 inline"
							>
								<path stroke-linecap="round" stroke-linejoin="round" d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
							</svg>
						</th>
						<th class="px-2 py-3 dark:text-zinc-200" @click="alteraSort('nivelFake')" v-if="usuarioAdmin.monitoramento">
							Fakes
							<svg
								v-if="query.sort.sort === 'nivelFake'"
								style="{ height: 22, marginLeft: 3 }"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke-width="1.5"
								stroke="currentColor"
								class="w-6 h-6 inline"
							>
								<path stroke-linecap="round" stroke-linejoin="round" d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
							</svg>
						</th>
						<th class="px-2 py-3 dark:text-zinc-200" @click="alteraSort('pcEmulador')">
							Local
							<svg
								v-if="query.sort.sort === 'pcEmulador'"
								style="{ height: 22, marginLeft: 3 }"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke-width="1.5"
								stroke="currentColor"
								class="w-6 h-6 inline"
							>
								<path stroke-linecap="round" stroke-linejoin="round" d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
							</svg>
						</th>
						<th class="px-2 py-3 dark:text-zinc-200" @click="alteraSort('pcEmulador')">Criador</th>
						<th class="px-2 py-3 dark:text-zinc-200" @click="alteraSort('lastPingWeb')">
							WEB
							<svg
								v-if="query.sort.sort === 'lastPingWeb'"
								style="{ height: 22, marginLeft: 3 }"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke-width="1.5"
								stroke="currentColor"
								class="w-6 h-6 inline"
							>
								<path stroke-linecap="round" stroke-linejoin="round" d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
							</svg>
						</th>
						<th class="px-2 py-3 dark:text-zinc-200" @click="alteraSort('qrcode.lastUpdate')">
							QRCode
							<svg
								v-if="query.sort.sort === 'qrcode.lastUpdate'"
								style="{ height: 22, marginLeft: 3 }"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke-width="1.5"
								stroke="currentColor"
								class="w-6 h-6 inline"
							>
								<path stroke-linecap="round" stroke-linejoin="round" d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
							</svg>
						</th>
						<th class="px-2 py-3 dark:text-zinc-200" v-if="query.envioRobo" @click="alteraSort('dataUltimoEnvioRobo')">
							Envio Robô
							<svg
								v-if="query.sort.sort === 'envioRobo'"
								style="{ height: 22, marginLeft: 3 }"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke-width="1.5"
								stroke="currentColor"
								class="w-6 h-6 inline"
							>
								<path stroke-linecap="round" stroke-linejoin="round" d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
							</svg>
						</th>
						<th class="px-2 py-3 dark:text-zinc-200">Opções</th>
					</tr>
				</thead>
				<tbody class="divide-y divide-gray-200 dark:divide-zinc-200">
					<tr class="text-sm text-center sm:text-left my-2 hover:bg-gray-200 dark:hover:bg-zinc-800 cursor-default" v-for="item in lista" :key="item._id">
						<td class="mx-1 px-2 py-3 font-normal text-gray-700 dark:text-zinc-200">
							<b class="cursor-pointer active:text-blue-700" @click="copiaNumero($event)">{{ item.telefone ? item.telefone : "Sem telefone" }}</b> - {{ " " }}
							{{ dataCriacao(item.data) }}
							{{ getInfoDate(item.data) }}
						</td>
						<td class="mx-1 px-2 py-3 font-normal text-gray-700 dark:text-zinc-200 w-64">
							<v-select
								v-if="item.usuario"
								class="bg-white dark:bg-zinc-800 dark:text-white dark:select-dark"
								v-model="item.usuario.email"
								:options="usuarios"
								label="email"
								@option:selecting="e => handleUpdateUser(item, e)"
							/>
						</td>
						<td class="mx-1 px-2 py-3 font-medium text-gray-700 dark:text-zinc-200">
							{{ item.envios }}
						</td>
						<td class="mx-1 px-2 py-3 font-medium text-blue-400 cursor-pointer" v-if="usuarioAdmin.monitoramento" @click="mostraModalChat(item._id)">
							{{ item.chatslzv2_hoje }}
						</td>
						<td class="mx-1 px-2 py-3 font-medium text-gray-700 dark:text-zinc-200" v-if="usuarioAdmin.monitoramento">
							{{ item.nivelFake > 0 ? item.nivelFake : 0 }}
							<svg
								v-if="item.nivelFake > 5"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke-width="1.5"
								stroke="currentColor"
								class="text-red-500 h-5 mt-1 inline"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
								/>
							</svg>
						</td>
						<td class="mx-1 px-2 py-3 font-medium text-gray-700 dark:text-zinc-200">
							<p class="font-medium text-zinc-800 dark:text-zinc-400">
								Socket: <span class="font-medium text-blue-500">{{ item.pc }}</span>
							</p>
							<p class="font-medium text-zinc-800 dark:text-zinc-400 mt-1">
								Emulador: <span class="font-medium text-blue-500">{{ item.pcEmulador }}</span>
							</p>
							<p class="font-medium text-zinc-800 dark:text-zinc-400 mt-1">
								SocketOBS: <span class="font-medium text-blue-500">{{ item?.pcSocketOBS }}</span>
							</p>
						</td>
						<td class="mx-1 px-2 py-3 font-medium text-gray-700 dark:text-zinc-200">
							{{ item.historico.length && item.historico[0] && item.historico[0].conta ? item.historico[0].conta.nome : "" }}
						</td>
						<td class="mx-1 px-2 py-3 font-medium text-gray-700 dark:text-zinc-200">
							<span
								@click="item.visualizarLastPing = !item.visualizarLastPing"
								class="text-xs text-white p-1 rounded cursor-pointer"
								:class="{'bg-red-500': verdeOuVermelho(item.lastPingWeb) === 'danger', 'bg-green-500': verdeOuVermelho(item.lastPingWeb) === 'success'}"
							>
								{{ verdeOuVermelho(item.lastPingWeb) === "danger" ? "OFFLINE" : "ONLINE" }}
								<br />
							</span>

							<span v-if="item.validador" class="text-xs text-white p-1 rounded cursor-pointer bg-blue-500"> VALIDADOR </span>

							<span v-if="item.lastPingWeb && item.visualizarLastPing" class="block pt-2">
								{{ dataOnline(item.lastPingWeb) }}
							</span>
						</td>
						<td class="mx-1 px-2 py-3 font-medium text-gray-700 dark:text-zinc-200">
							<label @click="mostraModalQRcode(item)" v-if="verdeOuVermelho(item.lastPingWeb) === 'danger'" class="text-blue-500 cursor-pointer">
								<span>QRCode</span>
							</label>
							<label
								v-if="verdeOuVermelho(item.lastPingWeb) === 'danger'"
								class="rounded-full inline-block ml-2 mb-1 w-2 h-2"
								:class="enviandoQRCode(item.qrcode) ? 'bg-green-500' : 'bg-red-500'"
							>
							</label>
							<span v-else class="text-xs text-zinc-500 p-1">
								{{ item.qrcode && item.qrcode.lastUpdate ? formataData(item.qrcode.lastUpdate, "DD/MM HH:mm:ss") : "Número duplicado (pesquisar em desativados)" }}
							</span>
						</td>
						<td v-if="query.envioRobo" class="mx-1 px-2 py-3 font-medium text-gray-700 dark:text-zinc-200">
							<span class="text-center">
								<span v-if="item.enviosRobo" class="block text-green-700 font-medium">Envios: {{ item.enviosRobo }}</span>
								{{ formataData(item.dataUltimoEnvioRobo, "DD/MM HH:mm") }}
							</span>
						</td>
						<td class="mx-1 px-2 py-3 font-medium text-gray-700 dark:text-zinc-200 text-sm">
							<button
								@click="restartStop(item.telefone, 'restart', item.pc)"
								class="px-2 py-1 my-1 mx-1 bg-blue-600 font-medium text-white text-sm border-b-2 border-r-2 border-blue-800 rounded-md transition duration-300 ease-in-out hover:shadow-md hover:bg-blue-800 hover:text-opacity-50"
							>
								Restart
							</button>
							<button
								@click="restartStop(item.telefone, 'stop', item.pc)"
								class="px-2 py-1 my-1 mx-1 bg-yellow-600 font-medium text-white text-sm border-b-2 border-r-2 border-yellow-800 rounded-md transition duration-300 ease-in-out hover:shadow-md hover:bg-yellow-800 hover:text-opacity-50"
								v-if="usuarioAdmin.monitoramento"
							>
								Stop
							</button>
							<button
								@click="restartStop(item.telefone, 'logout', item.pc)"
								class="px-2 py-1 my-1 mx-1 bg-gray-600 font-medium text-white text-sm border-b-2 border-r-2 border-gray-800 rounded-md transition duration-300 ease-in-out hover:shadow-md hover:bg-gray-800 hover:text-opacity-50"
							>
								Logout
							</button>
							<button
								@click="restartStop(item.telefone, 'stopsocket', item.pc)"
								class="px-2 py-1 my-1 mx-1 bg-rose-600 font-medium text-white text-sm border-b-2 border-r-2 border-rose-800 rounded-md transition duration-300 ease-in-out hover:shadow-md hover:bg-rose-800 hover:text-opacity-50"
							>
								Stop Socket
							</button>
							<button
								@click="buscaAuditoriaNumero(item.telefone)"
								class="px-2 py-1 my-1 mx-1 bg-pink-600 font-medium text-white text-sm border-b-2 border-r-2 border-pink-800 rounded-md transition duration-300 ease-in-out hover:shadow-md hover:bg-pink-800 hover:text-opacity-50"
								v-if="usuarioAdmin.monitoramento"
							>
								Auditoria
							</button>
							<button
								@click="mostraModalNumero(item)"
								class="px-2 py-1 my-1 mx-1 bg-green-600 font-medium text-white text-sm border-b-2 border-r-2 border-green-800 rounded-md transition duration-300 ease-in-out hover:shadow-md hover:bg-green-800 hover:text-opacity-50"
							>
								Editar
							</button>
							<button
								v-if="!query.desativo"
								@click="remover(item)"
								class="px-2 py-1 my-1 mx-1 bg-red-600 font-medium text-white text-sm border-b-2 border-r-2 border-red-800 rounded-md transition duration-300 ease-in-out hover:shadow-md hover:bg-red-800 hover:text-opacity-50"
							>
								Remover
							</button>
							<button
								v-if="query.desativo"
								@click="ativarNumero(item)"
								class="px-2 py-1 my-1 mx-1 bg-emerald-600 font-medium text-white text-sm border-b-2 border-r-2 border-emerald-800 rounded-md transition duration-300 ease-in-out hover:shadow-md hover:bg-emerald-800 hover:text-opacity-50"
							>
								Ativar
							</button>
							<button
								@click="router.push(`/admin/numeros/historico/${item._id}`)"
								class="px-2 py-1 my-1 mx-1 bg-indigo-600 font-medium text-white text-sm border-b-2 border-r-2 border-indigo-800 rounded-md transition duration-300 ease-in-out hover:shadow-md hover:bg-indigo-800 hover:text-opacity-50"
								v-if="usuarioAdmin.monitoramento"
							>
								Histórico
							</button>
							<a
								v-if="usuarioAdmin.monitoramento"
								:href="`${url}/v1/zap/analiticoEnvios/${item._id}/?token=${pegaToken()}`"
								type="button"
								class="px-2 py-1 my-1 mx-1 bg-cyan-600 font-medium text-white text-sm border-b-2 border-r-2 border-cyan-800 rounded-md transition duration-300 ease-in-out hover:shadow-md hover:bg-cyan-800 hover:text-opacity-50"
							>
								Analítico
							</a>
							<button
								v-if="usuarioAdmin.monitoramento"
								class="px-2 py-1 my-1 mx-1 bg-amber-600 font-medium text-white text-sm border-b-2 border-r-2 border-amber-800 rounded-md transition duration-300 ease-in-out hover:shadow-md hover:bg-amber-800 hover:text-opacity-50"
								@click="mostraModalEnvioTeste(item._id, item.telefone)"
							>
								Fazer envio
							</button>
							<button
								class="px-2 py-1 my-1 mx-1 bg-lime-600 font-medium text-white text-sm border-b-2 border-r-2 border-lime-800 rounded-md transition duration-300 ease-in-out hover:shadow-md hover:bg-lime-800 hover:text-opacity-50"
								@click="mostraModalEnvioCampanha(item._id)"
							>
								Fazer envio campanha
							</button>
							<button
								class="px-2 py-1 my-1 mx-1 bg-teal-600 font-medium text-white text-sm border-b-2 border-r-2 border-teal-800 rounded-md transition duration-300 ease-in-out hover:shadow-md hover:bg-teal-800 hover:text-opacity-50"
								@click="criarSocket(item.telefone)"
							>
								Criar Socket
							</button>
						</td>
					</tr>
				</tbody>
			</table>

			<Pagination :paginaInicial="query.page" :pageCount="query.pageCount" :fetchPagination="fetchPagination" />
			<ModalBaixarVolume :fazerDownload="false" :idZap="idZap" />
			<vue-final-modal v-model="showModal" name="numero" class="overflow-auto">
				<div id="config" class="bg-gray-100 dark:bg-dark rounded-md h-50 w-3/4 sm:w-60vh mx-auto translate-y-36 sm:translate-y-64 p-6">
					<svg
						@click="fechaModal('numero')"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						stroke-width="1.5"
						stroke="currentColor"
						class="text-red-500 w-6 h-6 cursor-pointer absolute top-2 right-5"
					>
						<path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
					</svg>

					<h1 class="text-xl text-zinc-500 font-medium mb-1 dark:text-white">
						{{ novoNumero ? "Novo Número" : "Editar Número" }}
					</h1>
					<form @submit.prevent="salvar">
						<label class="block my-2 text-sm text-header font-medium dark:text-zinc-200">Usuário</label>
						<v-select
							v-model="usuario_novoNumero"
							class="bg-white dark:bg-zinc-800 dark:text-white dark:select-dark"
							:options="usuarios"
							label="email"
							placeholder="Selecione um usuário"
						/>
						<label class="block my-2 text-sm text-header font-medium dark:text-zinc-200">Número</label>
						<CampoDeTexto v-model="numero" class="w-full h-10" tipo="text" holder="Digite o número" :obrigatorio="true" minlength="9" />
						<label class="block my-2 text-sm text-header font-medium dark:text-zinc-200"
							>PC Emulador <small class="my-2 text-xs text-red-600">(Não esqueça de atualizar o PC Emulador)</small></label
						>
						<CampoDeTexto v-model="emulador_novo" class="w-full h-10 mb-2" tipo="text" holder="PC Emulador" :obrigatorio="true" />
						<label class="block my-2 text-sm text-header font-medium dark:text-zinc-200">PC Socket OBS </label>
						<CampoDeTexto v-model="socketobs_novo" class="w-full h-10 mb-2" tipo="text" holder="PC Socket OBS" />
						<label class="text-sm text-zinc-500 font-bold">
							<input type="checkbox" v-model="validador_novo" @input="() => (validador_novo = !validador_novo)" />
							Validador
						</label>
						<label class="ml-4 text-sm text-zinc-500 font-bold" v-if="!novoNumero">
							<input type="checkbox" v-model="personalizar_canal" @input="() => (personalizar_canal = !personalizar_canal)" />
							Personalizar canal
						</label>
						<div class="grid grid-cols-1 my-5">
							<label class="text-sm text-zinc-500 font-bold">
								<input type="radio" v-model="criarVia" value="socket" />
								Criar via socket
							</label>
							<label class="text-sm text-zinc-500 font-bold">
								<input type="radio" v-model="criarVia" value="extensao" />
								Criar para extensão
							</label>
						</div>
						<div v-if="personalizar_canal" className="grid grid-cols-1">
							<label class="block my-2 text-sm text-header font-medium dark:text-zinc-200">Nome de exibição</label>
							<CampoDeTexto v-model="nomeDoCanal" class="w-full h-10" tipo="text" holder="Digite o nome de exibição" :obrigatorio="true" />
							<label class="block my-2 text-sm text-header font-medium dark:text-zinc-200">Foto de perfil</label>
							<input class="ml-1 my-2 text-zinc-200" ref="fotoDePerfilDoCanal" type="file" />
						</div>

						<hr class="my-3" />
						<button
							type="submit"
							class="hover:text-opacity-50 transition duration-300 ease-in-out dark:border-zinc-900 dark:bg-zinc-800 dark:hover:bg-zinc-900 bg-blue-600 border-b-2 border-r-2 border-blue-900 px-3 py-2 text-sm rounded-md text-white font-medium hover:bg-blue-900"
						>
							Salvar Número
						</button>
					</form>
				</div>
			</vue-final-modal>

			<vue-final-modal v-model="showQR" name="QRcode">
				<div id="config" class="bg-gray-100 dark:bg-dark rounded-md h-50 w-60vh mx-auto translate-y-36 sm:translate-y-64 p-6">
					<svg
						@click="fechaModal('QRcode')"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						stroke-width="1.5"
						stroke="currentColor"
						class="text-red-500 w-6 h-6 cursor-pointer absolute top-2 right-5"
					>
						<path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
					</svg>

					<h2 class="text-xl text-zinc-500 font-semibold mb-1 dark:text-white">{{ phone ? phone.telefone : "" }}</h2>
					<p class="text-lg text-zinc-500 dark:text-white">
						Último update {{ atualizado_socket ? "via socket " : "" }}: {{ qr ? formataData(qr.lastUpdate, "DD/MM HH:mm:ss") : "" }}
					</p>

					<div class="text-center my-5">
						<div class="flex justify-center">
							<img :src="qr && qr.qr ? qr.qr : ''" alt="QRcode" />
						</div>
						<span v-if="!qr"><b>QRcode indisponível</b></span>
					</div>
				</div>
			</vue-final-modal>

			<vue-final-modal :clickToClose="false" v-model="showCodigo" name="numeroCodigo">
				<div id="config" class="bg-gray-100 dark:bg-dark rounded-md h-50 w-60vh mx-auto translate-y-56 p-6">
					<svg
						@click="closeNumeroCodigo"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						stroke-width="1.5"
						stroke="currentColor"
						class="text-red-500 w-6 h-6 cursor-pointer absolute top-2 right-5"
					>
						<path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
					</svg>

					<h1 class="text-xl text-header font-bold mb-2 dark:text-white">Solicitação de novo número</h1>
					<Timer v-if="codigoNumero.numero !== 'Carregando...'" v-model="timer" />
					<p class="text-base font-medium mb-2 dark:text-white cursor-pointer">Última tentativa: {{ codigoNumero.ultimaTentativa }}</p>
					<hr class="my-3" />
					<p class="text-base text-header font-medium mb-2 dark:text-white cursor-pointer" @click="copiarNumeroGetCodigo()">
						Número:
						<span :class="codigoNumero.numero !== 'Carregando...' ? 'text-green-800' : 'text-yellow-600'"
							>{{ codigoNumero.country ? codigoNumero.country : "" }} {{ codigoNumero.numero ? codigoNumero.numero : "Carregando" }}</span
						>
					</p>
					<p class="text-base text-header font-medium mb-2 dark:text-white cursor-pointer" @click="copiarCodigoGetCodigo()">
						Código WhatsApp:
						<span :class="codigoNumero.codigo !== 'Carregando...' ? 'text-green-800' : 'text-yellow-600'">{{
							codigoNumero.codigo ? codigoNumero.codigo : "Carregando"
						}}</span>
					</p>
				</div>
			</vue-final-modal>

			<vue-final-modal v-model="envioTesteModal" name="envioTeste">
				<div id="config" class="bg-gray-100 dark:bg-dark rounded-md h-50 w/3-4 sm:w-60vh mx-auto translate-y-36 sm:translate-y-64 p-6">
					<svg
						@click="fechaModal('envioTeste')"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						stroke-width="1.5"
						stroke="currentColor"
						class="text-red-500 w-6 h-6 cursor-pointer absolute top-2 right-5"
					>
						<path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
					</svg>
					<h2 class="text-xl text-header font-bold mb-1 dark:text-white">Enviar mensagem</h2>
					<h3 class="text-lg text-zinc-500 font-semibold mb-1">
						Número para enviar: <span class="text-gray-400">{{ numeroResponsavelPorEnvioDigitos }}</span>
					</h3>
					<form @submit.prevent="enviaMensagem()">
						<label class="block mt-3 text-header font-medium dark:text-zinc-200">Vincular o número a um chat</label>
						<CampoDeTexto class="w-full" tipo="text" holder="Digite o _id do chat a ser vinculado" v-model="chatID" />

						<label class="block mt-3 text-header font-medium dark:text-zinc-200">Número</label>
						<CampoDeTexto
							id="number"
							:obrigatorio="chatID === ''"
							class="w-full"
							tipo="number"
							holder="Digite número que irá receber o envio"
							v-model="numeroParaReceber"
						/>

						<label class="block mt-3 text-header font-medium dark:text-zinc-200">Mensagem</label>
						<textarea
							rows="2"
							:required="true"
							class="w-full font-lato font-medium text-base border rounded-md box-border y-3 sm:py-5 px-2 focus:outline-none focus:border-blue-900 focus:ring-1 focus:ring-blue-900 hover:border-blue-300 hover:ring-1 hover:ring-blue-900 dark:bg-zinc-800 dark:focus:border-zinc-900 dark:focus:ring-1 dark:focus:ring-zinc-900 dark:hover:border-zinc-500 dark:hover:ring-1 dark:hover:ring-zinc-600 dark:border-zinc-700 dark:text-zinc-400 dark:placeholder:text-zinc-500"
							v-model="mensagem"
							tipo="text"
							placeholder="Digite a mensagem"
						></textarea>

						<input class="ml-1 my-2 text-zinc-200" ref="fileOutPut" type="file" />
						<button
							type="submit"
							class="dark:border-zinc-900 dark:bg-zinc-800 dark:hover:bg-zinc-900 bg-blue-600 border-b-2 border-r-2 border-blue-900 flex mt-3 px-3 py-2 text-sm rounded-md text-white font-medium hover:bg-blue-900 transition duration-300 ease-in-out"
						>
							Enviar mensagem
						</button>
					</form>
					<button
						@click="limparLocalStorage()"
						class="mt-3 absolute right-7 bottom-6 dark:border-zinc-900 dark:bg-zinc-800 dark:hover:bg-zinc-900 bg-blue-600 border-b-2 border-r-2 border-blue-900 px-3 py-2 text-sm rounded-md text-white font-medium hover:bg-blue-900 transition duration-300 ease-in-out"
					>
						Limpar LocalStorage
					</button>
				</div>
			</vue-final-modal>

			<vue-final-modal v-model="limpezaModal" name="limpezaModal" class="overflow-auto">
				<div id="config" class="bg-gray-100 dark:bg-dark rounded-md h-50 w-60vh mx-auto translate-y-24 p-6">
					<svg
						@click="fechaModal('limpezaModal')"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						stroke-width="1.5"
						stroke="currentColor"
						class="text-red-500 w-6 h-6 cursor-pointer absolute top-2 right-5"
					>
						<path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
					</svg>
					<h2 class="text-xl text-zinc-500 dark:text-white font-semibold mb-1">Limpeza de pcs</h2>
					<h3 class="text-zinc-500 dark:text-white font-semibold mb-1">PCS limpos do usuário {{ usuarioAdmin.email }}: {{ pcsLimpos }}</h3>
					<form class="grid grid-cols-1 mt-5" @submit.prevent="limpaPc()">
						<p class="text-sm text-zinc-500 dark:text-white mb-1">Marque o pc emulador que você <span class="font-bold text-blue-700"> já fez </span>a limpeza</p>

						<label class="block mt-3 text-header font-medium dark:text-zinc-200">Emulador</label>
						<CampoDeTexto :obrigatorio="true" class="col-span-1" tipo="text" holder="Digite o pc emulador que você terminou de limpar" v-model="pcLimpado" />
						<button
							type="submit"
							class="mt-3 dark:border-zinc-900 dark:bg-zinc-800 dark:hover:bg-zinc-900 bg-blue-600 border-b-2 border-r-2 border-blue-900 px-3 py-2 text-sm rounded-md text-white font-medium hover:bg-blue-900 transition duration-300 ease-in-out"
						>
							Registrar limpeza do emulador
						</button>
					</form>
				</div>
			</vue-final-modal>

			<vue-final-modal v-model="chatModal" name="chatModal" class="overflow-auto">
				<div id="config" class="bg-gray-100 dark:bg-dark rounded-md h-50 w-60vh mx-auto translate-y-24 p-6">
					<svg
						@click="fechaModal('chatModal')"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						stroke-width="1.5"
						stroke="currentColor"
						class="text-red-500 w-6 h-6 cursor-pointer absolute top-2 right-5"
					>
						<path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
					</svg>
					<h2 class="text-xl text-zinc-500 dark:text-white font-semibold mb-1">Chats: {{ dadosChat.total }}</h2>
					<p class="text-sm text-zinc-500 mb-1">{{ dadosChat.descricaoQTDChat }}</p>
					<div class="bg-white dark:bg-black my-5 px-2 py-2 shadow rounded-md h-96 overflow-auto">
						<table class="w-full divide-y divide-gray-200 dark:divide-zinc-200">
							<thead class="select-none">
								<tr class="text-header dark:text-zinc-200 sm:text-left text-sm sm:text-base">
									<th class="sm:px-2 py-3">Telefone</th>
									<th class="sm:px-2 py-3">Primeiro contato</th>
								</tr>
							</thead>
							<tbody class="divide-y divide-gray-200 dark:divide-zinc-200">
								<tr class="text-sm my-2 hover:bg-gray-200 dark:hover:bg-zinc-800" v-for="(conversa, index) in dadosChat.conversas" :key="index">
									<td class="sm:px-2 py-3 dark:text-zinc-200 text-gray-600">{{ conversa.contato.telefone }}</td>
									<td class="sm:px-2 py-3 dark:text-zinc-200 text-gray-600">{{ formataData(conversa.dataPrimeiroContato, "DD/MM HH:mm") }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</vue-final-modal>

			<vue-final-modal v-model="auditoriaNumero.exibir" name="auditoriaNumeroModal" class="overflow-auto">
				<div id="config" class="bg-gray-100 rounded-md h-50 w-60vh mx-auto translate-y-24 p-6">
					<svg
						@click="fechaModal('auditoriaNumeroModal')"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						stroke-width="1.5"
						stroke="currentColor"
						class="text-red-500 w-6 h-6 cursor-pointer absolute top-2 right-5"
					>
						<path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
					</svg>
					<h2 class="text-xl text-zinc-500 font-semibold mb-1">Auditoria: {{ auditoriaNumero.numero }}</h2>
					<div class="bg-white my-5 px-2 py-2 shadow rounded-md h-96 overflow-auto">
						<table class="w-full divide-y divide-gray-200">
							<thead class="select-none">
								<tr class="text-header sm:text-left text-sm sm:text-base">
									<th class="sm:px-2 py-3">Usuário</th>
									<th class="sm:px-2 py-3">Status</th>
									<th class="sm:px-2 py-3">Ação</th>
									<th class="sm:px-2 py-3">Data</th>
								</tr>
							</thead>
							<tbody class="divide-y divide-gray-200">
								<tr class="text-sm my-2 hover:bg-gray-200" v-for="(log, index) in auditoriaNumero.usuarios" :key="index">
									<td class="sm:px-2 py-3 text-gray-600">{{ log.usuario.nome }}</td>
									<td class="sm:px-2 py-3 text-gray-600">{{ log.status }}</td>
									<td class="sm:px-2 py-3 text-gray-600">{{ log.tipo }}</td>
									<td class="sm:px-2 py-3 text-gray-600">{{ formataData(log.data, "DD/MM HH:mm") }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</vue-final-modal>
			<ModalEnvioChats @envioRealizado="updateQuery()" />
			<ModalStatusMsg />
			<EnviarQtdCampanha />
		</div>
	</div>
</template>
<script setup>
	import {ref, watch, inject, onMounted, onUnmounted, computed, onBeforeMount} from "vue";
	import {pega_usuarios} from "@/store/typeActions.js";
	import {getToken} from "@/services/auth.js";
	import api from "@/services/api.js";
	import Swal from "sweetalert2";
	import TituloPagina from "@/components/tituloPagina.vue";
	import CampoDeTexto from "@/components/campoDeTexto.vue";
	import Pagination from "@/components/pagination.vue";
	import ModalStatusMsg from "./modais/modalStatusMsg.vue";
	import ModalEnvioChats from "./modais/modalEnvioChats.vue";
	import EnviarQtdCampanha from "./modais/enviarQtdCampanha.vue";
	import Tabela from "./components/tabela.vue";
	import ModalBaixarVolume from "@/views/home/modalBaixarVolume.vue";
	import Fluxograma from "./components/fluxograma.vue";
	import Timer from "./components/timer.vue";
	import {useStore} from "vuex";
	import {useRoute, useRouter} from "vue-router";
	import {useLoading} from "vue-loading-overlay";
	import moment from "moment";
	import {formataData} from "@/hooks/formatDate";
	import {toast} from "@/hooks/toast";

	const router = useRouter();
	const route = useRoute();
	const store = useStore();
	const $vfm = inject("$vfm");
	const $socket = inject("$socket");
	const $loading = useLoading({});
	const emulador_novo = ref("");
	const socketobs_novo = ref("");
	const validador_novo = ref(false);
	const personalizar_canal = ref(false);
	const nomeDoCanal = ref("");
	const fotoDePerfilDoCanal = ref(null);
	const usuario_novoNumero = ref(null);
	const novoNumero = ref(true);
	const showModal = ref(false);
	const dadosChat = ref({total: 0, conversas: [], descricaoQTDChat: ""});
	const chatModal = ref(false);
	const limpezaModal = ref(false);
	const pcsLimpos = ref(0);
	const pcLimpado = ref("");
	const showQR = ref(false);
	const showCodigo = ref(false);
	const envioTesteModal = ref(false);
	const idZap = ref("");
	const numero = ref("");
	const id = ref(null);
	const lista = ref([]);
	const total = ref(0);
	const atualizado_socket = ref(false);
	const qr = ref(null);
	const user_QRcode = ref(null);
	const phone = ref(null);
	const numerosParaCopiar = ref([]);
	const mensagem = ref("");
	const numeroParaReceber = ref("");
	const criarVia = ref("socket");
	const chatID = ref("");
	const fileOutPut = ref(null);
	const numeroResponsavelPorEnvio = ref("");
	const numeroResponsavelPorEnvioDigitos = ref("");
	const updateBusca = ref(new Date());
	const usuarioAdmin = ref(store.state.usuario);
	const filtro = ref("Criação");
	const usuarios = computed(() => store.state.usuarios);
	const url = `${process.env.VUE_APP_API}`;

	const auditoriaNumero = ref({
		exibir: false,
		numero: "",
		usuarios: [],
	});
	const timer = ref(120);
	const intervalId = ref(null);
	const codigoNumero = ref({
		ativo: false,
		awaitCodigo: false,
		id: null,
		country: null,
		numero: "Carregando...",
		codigo: "Carregando...",
		fornecedor: "grizzlysms",
		ultimaTentativa: "",
	});
	const query = ref({
		buscaArray: "",
		tipoConta: "",
		diasCriacao: "0",
		usuario: null,
		statusWebOffline: false,
		statusWebOnline: false,
		statusQrCode: false,
		desativo: false,
		exatoEmulador: false,
		exatoSocket: false,
		envioRobo: false,
		naoContemSocket: false,
		naoContemEmulador: false,
		semSocket: false,
		searchOption: "buscar",
		pcEmulador: "",
		pcSocket: "",
		de: null,
		ate: null,
		sort: {sort: "qrcode.lastUpdate", ordem: -1},
		limit: 50,
		skip: 0,
		pageCount: 0,
		page: 1,
	});
	const contas = ref({
		fluxoPrincipal: [{nome: "", total: 0, online: 0, offs: 0}],
		controleEnvios: [{nome: "", total: 0, online: 0}],
		contasEnvios: [{nome: "", total: 0, online: 0}],
		clientesPersonalizados: [{nome: "", total: 0, online: 0}],
		etapas: [{nome: "", total: 0, descricao: ""}],
		emuladores: [{nome: "", total: 0, online: 0}],
		sockets: [{nome: "", total: 0, online: 0}],
		total_conversas: 0,
		data: [],
	});

	const limparFiltros = () => {
		query.value.buscaArray = "";
		query.value.tipoConta = "";
		query.value.diasCriacao = "0";
		query.value.searchOption = "buscar";
		query.value.usuario = null;
		query.value.statusWebOffline = false;
		query.value.statusWebOnline = false;
		query.value.statusQrCode = false;
		query.value.desativo = false;
		query.value.de = null;
		query.value.ate = null;
		query.value.exatoEmulador = false;
		query.value.exatoSocket = false;
		query.value.envioRobo = false;
		query.value.naoContemSocket = false;
		query.value.naoContemEmulador = false;
		query.value.pcEmulador = "";
		query.value.pcSocket = "";
		query.value.de = null;
		query.value.ate = null;
		query.value.sort = {sort: "qrcode.lastUpdate", ordem: -1};
		query.value.limit = 50;
		query.value.skip = 0;
		query.value.pageCount = 0;
		query.value.page = 1;

		updateQuery();
	};

	const fetchData = async () => {
		const response_zaps = await api.post(`/v1/zap/list`, query.value);

		total.value = response_zaps.data.total;
		query.value.pageCount = Math.ceil(response_zaps.data.total / query.value.limit);
		lista.value = response_zaps.data.data;
		numerosParaCopiar.value = lista.value.map(item => item.telefone);
		updateBusca.value = response_zaps.data.horarioServidor;
	};

	const updateQuery = () => {
		query.value.skip = 0;
		query.value.page = 1;
		query.value.pageCount = 0;

		realizarPush();
	};

	const fetchPagination = page => {
		window.scrollTo(0, 0);
		query.value.skip = (page - 1) * query.value.limit;
		query.value.page = page;

		realizarPush();
	};

	const limpaRadioConta = value => {
		if (query.value.tipoConta === value) {
			query.value.tipoConta = "";
		}
	};

	const salvar = async () => {
		if (!usuario_novoNumero.value) return toast("Nenhum usuário selecionado!", "error");
		if (numero.value.trim() === "") return toast("Campo número de telefone está vazio!", "error");
		if (numero.value.trim().length < 10) return toast("Campo número de telefone está incorreto, verifique espaços ou outros caracteres!", "error");
		if (emulador_novo.value.trim() === "") return toast("Campo emulador está vazio!", "error");
		if (personalizar_canal.value && nomeDoCanal.value.trim() === "") return toast("Campo nome de exibição está vazio!", "error");
		if (personalizar_canal.value && fotoDePerfilDoCanal.value === null) return toast("Campo foto de perfil está vazio!", "error");

		const data = {
			telefone: numero.value.trim(),
			usuario: usuario_novoNumero.value._id,
			validador: validador_novo.value,
			pcEmulador: emulador_novo.value.trim(),
			pcSocketOBS: socketobs_novo.value.trim(),
			criarVia: criarVia.value,
		};

		if (personalizar_canal.value && fotoDePerfilDoCanal.value !== null) {
			const formData = new FormData();
			formData.append("file", fotoDePerfilDoCanal.value.files[0]);

			const response = await api.post(`/upload`, formData);
			const url = `${process.env.VUE_APP_API}/upload?mimetype=image/jpeg&filename=${response.data.file.filename}&folder=arquivosWhats`;

			data.fotoDePerfilDoCanal = url;
			data.personalizarCanal = personalizar_canal.value;
			data.nomeDoCanal = nomeDoCanal.value;
		}

		if (novoNumero.value) {
			const response = await api.post(`/v1/zap`, data);
			if (!response.data.success) return toast(response.data.err, "error");
		} else {
			data._id = id.value;
			const response = await api.put(`/v1/zap`, data);
			if (!response.data.success) return toast(response.data.err, "error");
		}

		await fetchData();
		toast("Sucesso!", "success");
		fechaModal("numero");
	};

	const openModalPcLimpeza = async () => {
		const response = await api.post("/v1/users/getPcsLimposHoje");
		const {pcs} = response.data;
		pcsLimpos.value = pcs;

		$vfm.show("limpezaModal");
	};

	const limpaPc = async () => {
		const {isConfirmed} = await Swal.fire({
			title: "Limpando PC",
			icon: "question",
			text: `O PC a ser limpo é ${pcLimpado.value}?`,
			showCancelButton: true,
			confirmButtonText: "Sim",
			cancelButtonText: "Não",
		});

		if (isConfirmed) {
			if (pcLimpado.value.trim() === "") {
				return toast("O campo de emulador está vazio!", "error");
			}

			const response = await api.post("v1/users/registrarQuantidadeLimpeza", {pcLimpado: pcLimpado.value});

			if (!response.data.success) return toast(response.data.err, "error");

			fechaModal("limpezaModal");
			toast("Sucesso ao registrar limpeza no emulador!", "success");

			const responseData = await api.post("/v1/users/getPcsLimposHoje");
			const {pcs} = responseData.data;
			pcsLimpos.value = pcs;
		}
	};

	const download = async () => {
		await fetchData();

		try {
			const response = await api.post("/v1/zap/downloadNumeros", {data: query.value, responseType: "blob"});

			if (response.data.noSuccess) {
				return toast(response.data.err, "error");
			}

			const blob = new Blob([response.data], {type: "text/csv"});
			const url = window.URL.createObjectURL(blob);

			const tag_a_ = document.createElement("a");
			tag_a_.href = url;
			tag_a_.download = "numerosDash.csv";

			tag_a_.click();
			tag_a_.remove();

			window.URL.revokeObjectURL(url);
		} catch (error) {
			toast("Erro ao fazer download, tente novamente em instantes...", "error");
		}
	};

	const ativarNumero = async numero => {
		const confirmacao = await Swal.fire({
			title: "Ativar Número",
			text: `Ativar número ${numero.telefone} da conta ${numero.usuario.email}?`,
			icon: "question",
			confirmButtonText: "Sim",
			showCancelButton: true,
			cancelButtonText: "Não",
		});

		if (confirmacao.value) {
			numero.ativo = true;
			const response = await api.put(`/v1/zap/`, {_id: numero._id, ativo: true});
			if (!response.data.success) return toast(response.data.err, "success");

			toast("Sucesso ao ativar número!", "success");
			await fetchData();
		}
	};

	const remover = async zap => {
		let reportarBanimento = false;
		const numeroComMaisDeUmDia = moment().diff(moment(zap.data).startOf("day"), "days") >= 1;

		const confirmacao = await Swal.fire({
			title: "Remover Número",
			text: `Remover número ${zap.telefone} da conta ${zap.usuario.email}?`,
			icon: "question",
			input: numeroComMaisDeUmDia ? "checkbox" : "",
			inputValue: reportarBanimento,
			inputPlaceholder: "Reportar banimento",
			confirmButtonText: "Sim",
			showCancelButton: true,
			cancelButtonText: "Não",
		});

		if (confirmacao.isConfirmed) {
			reportarBanimento = confirmacao.value && numeroComMaisDeUmDia;

			const response = await api.delete(`/v1/zap/one/${zap._id}?reportBan=${reportarBanimento}`);
			if (!response.data.success) return toast(response.data.err, "error");

			await fetchData();
			toast("Sucesso ao enviar comando!", "success");
		}
	};

	const restartStop = async (telefone, comando, pc) => {
		if (comando === "logout" || comando === "stopsocket") {
			const confirmacao = await Swal.fire({
				title: `Realizar ${comando}?`,
				text: `Fazer ${comando} do telefone ${telefone}?`,
				icon: "question",
				confirmButtonText: "Sim",
				showCancelButton: true,
				cancelButtonText: "Não",
			});

			if (confirmacao.value) {
				const response = await api.post(`/v1/zap/restartStop`, {telefone: telefone, comando: comando, pc: pc});
				if (!response.data.success) return toast(response.data.err, "error");

				await fetchData();
				return toast("Sucesso ao enviar comando!", "success");
			}

			return;
		}

		const response = await api.post(`/v1/zap/restartStop`, {telefone: telefone, comando: comando, pc: pc});
		if (!response.data.success) return toast(response.data.err, "error");

		await fetchData();
		return toast("Sucesso ao enviar comando!", "success");
	};

	const handleUpdateUser = async (usuario, e) => {
		const response = await api.put(`/v1/zap`, {_id: usuario._id, usuario: e._id});
		if (!response.data.success) return toast(response.data.err, "error");

		await fetchData();
		toast("Sucesso ao enviar comando!", "success");
	};

	const mostraModalNumero = async usuario => {
		if (usuario) {
			novoNumero.value = false;
			usuario_novoNumero.value = usuario.usuario;
			numero.value = usuario.telefone;
			emulador_novo.value = usuario.pcEmulador;
			validador_novo.value = usuario.validador;
			id.value = usuario._id;
		} else {
			limpaCampos();
		}

		$vfm.show("numero");
	};

	async function getCodigo(ID) {
		const intervalo = 10000; // 10 segundos

		const fazerRequisicao = async ID => {
			if (!codigoNumero.value.numero) return clearInterval(intervalId.value);
			if (!codigoNumero.value.id) return clearInterval(intervalId.value);
			if (codigoNumero.value.ativo) return clearInterval(intervalId.value);

			try {
				const response = await api.get(`/v1/zap/getNumeroCodigo/${ID}?fornecedor=${codigoNumero.value.fornecedor}`);
				if (response.data.success) {
					codigoNumero.value.codigo = response.data.codigo;
					codigoNumero.value.awaitCodigo = false;
					codigoNumero.value.ativo = true;
					clearInterval(intervalId.value);
				} else {
					console.log("Tentativa falhou, tentando novamente em 10 segundos:", response.data);
				}

				codigoNumero.value.ultimaTentativa = moment().format("HH:mm:ss");
			} catch (error) {
				console.error("Erro na requisição:", error);
			}
		};

		intervalId.value = setInterval(() => fazerRequisicao(ID), intervalo);
		fazerRequisicao(ID);
	}

	const mostraModalNumeroCodigo = async () => {
		const loader = $loading.show({canCancel: false, isFullPage: true});
		codigoNumero.value = {ativo: false, awaitCodigo: false, id: null, country: null, numero: "Carregando...", codigo: "Carregando...", ultimaTentativa: ""};

		const response = await api.post(`/v1/zap/getNumeroCodigo`);
		if (response.data.success) {
			codigoNumero.value.country = response.data.country;
			codigoNumero.value.numero = response.data.numero;
			codigoNumero.value.id = response.data.id;
			codigoNumero.value.awaitCodigo = true;
			codigoNumero.value.fornecedor = response.data.fornecedor;
			codigoNumero.value.ultimaTentativa = "";
			timer.value = 120;
			$vfm.show("numeroCodigo");
			loader.hide();
			await getCodigo(response.data.id);
		} else {
			loader.hide();
			return toast(response.data.err, "error");
		}
	};

	async function copiarNumeroGetCodigo() {
		try {
			await navigator.clipboard.writeText(codigoNumero.value.numero);
			toast("Copiado!", "info");
		} catch ($e) {}
	}

	async function copiarCodigoGetCodigo() {
		try {
			await navigator.clipboard.writeText(codigoNumero.value.codigo);
			toast("Copiado!", "info");
		} catch ($e) {}
	}

	const closeNumeroCodigo = async () => {
		if (timer.value <= 0) {
			await api.delete(`/v1/zap/deleteNumeroCodigo/${codigoNumero.value.id}`);
			codigoNumero.value = {ativo: false, awaitCodigo: false, id: null, country: null, numero: "Carregando...", codigo: "Carregando...", ultimaTentativa: ""};
			$vfm.hide("numeroCodigo");
		} else {
			toast("Espere o timer finalizar", "error");
		}
	};

	const mostraModalQRcode = async usuario => {
		phone.value = usuario;
		user_QRcode.value = phone.value.usuario;
		if (phone.value && phone.value._id) {
			const response = await api.get(`/v1/zap/${phone.value._id}?usuario=${user_QRcode.value._id}&getQR=${true}`);
			qr.value = response.data.data.qrcode;
		}
		$vfm.show("QRcode");
	};

	const buscaAuditoriaNumero = async numero => {
		auditoriaNumero.value.numero = numero;

		const response = await api.post("v1/users/list-auditoria", {telefone: numero});
		if (!response.data.success) return toast(response.data.err, "error");

		auditoriaNumero.value.usuarios = response.data.usuarios;
		$vfm.show("auditoriaNumeroModal");
	};

	const mostraModalChat = async id => {
		const response = await api.get(`/v1/zap/conversas/${id}`);

		if (response.data.total > 0) {
			dadosChat.value = response.data;
			$vfm.show("chatModal");
			return;
		}

		toast("Sem chats para esse número!", "error");
	};

	const mostraModalEnvioTeste = (numero_id, numero) => {
		if (localStorage.getItem("numero") && localStorage.getItem("mensagem")) {
			numeroParaReceber.value = localStorage.getItem("numero");
			mensagem.value = localStorage.getItem("mensagem");
		}

		numeroResponsavelPorEnvio.value = numero_id;
		numeroResponsavelPorEnvioDigitos.value = numero;
		$vfm.show("envioTeste");
	};

	const mostraModalEnvioCampanha = zap => {
		idZap.value = zap;
		$vfm.show("baixarVolumeLzv2");
	};

	const criarSocket = async numero => {
		const confirmacao = await Swal.fire({
			title: `Criar Socket?`,
			text: `Deseja mesmo criar um Socket?`,
			icon: "question",
			confirmButtonText: "Sim",
			showCancelButton: true,
			cancelButtonText: "Não",
		});

		if (confirmacao.value) {
			const loader = $loading.show({canCancel: false, isFullPage: true});

			const response = await api.post(`v1/zap/socket`, {numero});
			if (!response.data.success) {
				loader.hide();
				return toast(response.data.err, "error");
			}

			loader.hide();
			toast(response.data.msg, "success");
			await fetchData();
		}
	};

	const enviaMensagem = async () => {
		const formData = new FormData();

		formData.append("file", fileOutPut.value.files[0]);
		formData.append("chatID", chatID.value.trim());
		formData.append("numeroParaEnviar", numeroResponsavelPorEnvio.value);
		formData.append("numeroParaReceber", numeroParaReceber.value);
		formData.append("mensagem", mensagem.value);

		const response = await api.post(`/v1/zap/enviarTeste`, formData);

		if (!response.data.success) {
			return toast(response.data.err, "error");
		}

		localStorage.setItem("numero", numeroParaReceber.value);
		localStorage.setItem("mensagem", mensagem.value);

		toast("Mensagem enviada com sucesso", "success");
		fechaModal("envioTeste");
	};

	const realizarPush = () =>
		router.push({
			path: `/admin/numeros`,
			query: {
				...query.value,
				filtro: filtro.value,
				sort: query.value.sort.sort,
				ordem: query.value.sort.ordem,
				m: moment().format("ss"),
			},
		});

	const limparLocalStorage = () => {
		localStorage.removeItem("numero");
		localStorage.removeItem("mensagem");
		toast("Local storage limpo!", "success");
	};

	const enviandoQRCode = qr => {
		if (!qr || !qr.lastUpdate) return false;

		const diff = moment().diff(qr.lastUpdate, "seconds");
		if (diff > 70) return false;
		return true;
	};

	const limpaCampos = () => {
		novoNumero.value = true;
		numero.value = "";
		validador_novo.value = false;
	};

	const verdeOuVermelho = time => {
		const diff = moment(updateBusca.value).diff(moment(time), "seconds");

		if (diff > 200 || !time) return "danger";
		return "success";
	};

	const copiaNumero = event => {
		navigator.clipboard.writeText(event.target.innerText);
		toast("Copiado!", "info");
	};

	const copiaTodosOsNumeros = () => {
		const numerosFormatados = numerosParaCopiar.value.join("\n");
		navigator.clipboard.writeText(numerosFormatados);
		toast("Copiado!", "info");
	};

	const getCountDays = data => moment().startOf("D").diff(moment(data).startOf("D"), "d");
	const dataCriacao = data => moment(data).format("DD/MM/YYYY HH:mm");
	const dataOnline = data => moment(data).format("HH:mm:ss");
	const getInfoDate = data => `(há ${getCountDays(data)} dia${getCountDays(data) > 1 ? "s" : ""})`;
	const fechaModal = nome => $vfm.hide(nome);
	const pegaToken = () => getToken();
	const mostraModal = nome => $vfm.show(nome);

	const alteraSort = NomeDoSort => {
		if (query.value.sort.sort !== NomeDoSort) {
			query.value.sort.sort = NomeDoSort;
			query.value.sort.ordem = -1;
		} else {
			query.value.sort.ordem *= -1;
		}

		realizarPush();
	};

	const qrSocket = qrSocket => {
		if (phone.value && qrSocket && qrSocket.telefone && phone.value.telefone.toString() === qrSocket.telefone.toString()) {
			qr.value = qrSocket;
			atualizado_socket.value = true;
		}
	};

	const getOnlines = async () => {
		const response_onlines = await api.post(`/v1/zap/get_onlines`, {filtro: filtro.value});
		contas.value = response_onlines.data;
	};

	const changeRouter = async () => {
		if (route.query.skip) query.value.skip = parseInt(route.query.skip);
		if (route.query.pageCount) query.value.pageCount = parseInt(route.query.pageCount);
		if (route.query.page) query.value.page = parseInt(route.query.page);
		if (route.query.limit) query.value.limit = parseInt(route.query.limit);
		if (route.query.buscaArray) query.value.buscaArray = route.query.buscaArray;
		if (route.query.tipoConta) query.value.tipoConta = route.query.tipoConta;
		if (route.query.searchOption) query.value.searchOption = route.query.searchOption;
		if (route.query.usuario) query.value.usuario = route.query.usuario;
		if (route.query.statusWebOffline) query.value.statusWebOffline = route.query.statusWebOffline === "false" ? false : true;
		if (route.query.statusWebOnline) query.value.statusWebOnline = route.query.statusWebOnline === "false" ? false : true;
		if (route.query.envioRobo) query.value.envioRobo = route.query.envioRobo === "false" ? false : true;
		if (route.query.statusQrCode) query.value.statusQrCode = route.query.statusQrCode === "false" ? false : true;
		if (route.query.desativo) query.value.desativo = route.query.desativo === "false" ? false : true;
		if (route.query.exatoEmulador) query.value.exatoEmulador = route.query.exatoEmulador === "false" ? false : true;
		if (route.query.exatoSocket) query.value.exatoSocket = route.query.exatoSocket === "false" ? false : true;
		if (route.query.naoContemEmulador) query.value.naoContemEmulador = route.query.naoContemEmulador === "false" ? false : true;
		if (route.query.naoContemSocket) query.value.naoContemSocket = route.query.naoContemSocket === "false" ? false : true;
		if (route.query.pcEmulador) query.value.pcEmulador = route.query.pcEmulador;
		if (route.query.pcSocket) query.value.pcSocket = route.query.pcSocket;
		if (route.query.de) query.value.de = route.query.de;
		if (route.query.ate) query.value.ate = route.query.ate;
		if (route.query.filtro) filtro.value = route.query.filtro;
		if (route.query.diasCriacao) query.value.diasCriacao = route.query.diasCriacao;
		if (route.query.sort && route.query.ordem) {
			query.value.sort.sort = route.query.sort;
			query.value.sort.ordem = parseInt(route.query.ordem);
		}

		await getOnlines();
		await fetchData();
	};

	onBeforeMount(async () => await Promise.all([store.dispatch(pega_usuarios, false), changeRouter()]));
	onMounted(async () => $socket.on("qr", qrSocket));
	onUnmounted(() => {
		$socket.off("qr");
		clearInterval(intervalId.value);
	});

	watch(
		() => query.value.desativo,
		async () => await fetchData()
	);
	watch(
		() => route,
		async () => await changeRouter(),
		{deep: true}
	);
	watch([filtro], () => {
		if (filtro.value !== null) realizarPush();
	});
</script>
